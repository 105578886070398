.top-icons {
  position: relative;
  display: block;

  /* Phones */
  @media only screen and (max-width : 480px) {
    display: none;
  }

  &__icons {
    padding: 2em 3em;
  }

  &__social {
    position: absolute;
    top: 5%;
    width: 5em;
  }

  &__logo {
    position: absolute;
    top: 20%;
    right: 3%;
    width: 6.5em;
  }
}

.banner {
  position: relative;

  &__dark {
    background-image: url(images/EB_After_Dark_banner.jpg);
    background-size: cover;
    background-position: top;
  }

  &__evlogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 55%;
    margin-top: -9%;

    /* Extra Small Devices, Phones */
    @media only screen and (max-width : 480px) {
      width: 80%;
      margin-top: 0;
      padding-top: 4.5em;
    }
  }

  &__evlogo-small {
    margin-left: 32%;
    width: 35%;
    margin-top: -4%;

    // /* Tablets */
    @media only screen and (max-width : 768px) {
      margin-left: 15%;
      width: 70%;
      margin-top: -5%;
    }

    //* Phones */
    @media only screen and (max-width : 480px) {
      margin-top: 16%;
    }

    //* Extra Small Phones */
    @media only screen and (max-width : 320px) {
      margin-top: 25%;
    }
  }

  &__introdark {
    color: $color-white;
    font-family: Arvo;
    font-weight: 400;
    font-size: 2rem;
    text-transform: uppercase;
    padding-bottom: 1em;
    margin: 0 3em;

    /* Extra Small Devices, Phones */
    @media only screen and (max-width : 480px) {
      margin: 0 2em;
    }
  }

  &__darksub {
    text-align: center;
    color: $color-white;
    font-family: Arvo;
    font-weight: 400;
    font-size: 1.4rem;
    text-transform: uppercase;
    padding-bottom: 1em;
    margin: 0 7em;

    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {
      margin: 0 0.5em;
    }
  }

  &__intro {
    color: $color-text;
    font-family: Arvo;
    margin: 0 3em;

    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {
      margin: 0 0.5em;
    }
  }
}

.main {
  &__small {
    margin-bottom: 5em;
  }
}

