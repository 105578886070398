
$color-primary: #293046;
$color-green: #92c04f;
$color-white: #ffffff;
$color-dark-grey: #363636;
$color-black: #000000;
$color-light: #f6f6f6;
$color-text: #4c565e;
$color-blue: #2c3f78;
$color-light-blue: #84b7e6;
$color-light-red: #c4686c;
$color-red: #941b24;
$color-logoblue: #3856a2;
$color-background: #00142d;


