.wrapper {
  margin: 0 auto;

  &__dark {
    background-color: $color-background;
    padding-bottom: 7em;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    padding: 0 0.5rem;
  }
}

