body {
  font-family: Lato, sans-serif;
  font-size: 1rem;
  color: $color-primary;
}

p {
  color: $color-text;
  line-height: 1.7;
  font-family: Lato;
  font-weight: 400;
  margin: 0;

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    font-size: 16pt;
  }
}

b {
  font-family: Arvo;
  font-weight: 400;
  text-transform: uppercase;

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    font-size: 1.4rem;
  }
}

h1 {
  color: $color-text;
  font-family: Arvo;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.4em !important;
  text-align: center;
  padding-top: 50px;
  text-transform: uppercase;
  padding-bottom: 1em;
  margin: 0 2em;
}

h2 {
  text-align: center;
  font-size: 1.3rem;
  color: $color-text;
  font-family: Lato;
  font-weight: 400;
  margin: 1em 5em;
  line-height: 1.4em;

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    margin: 1em 1em;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 992px) {
    margin: 1em 1em;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    font-size: 1.7rem;
    margin: 1em 1em;
    line-height: 1.4em;
  }
}

h3 {
  text-align: left;
  font-size: 1.6rem;
  color: $color-text;
  font-family: Arvo;
  font-weight: 400;
  // margin-top: 1em;
  text-transform: uppercase;

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    font-size: 1.7rem;
    margin: 1em 1em;
  }
}

h4 {
  text-align: left;
  font-size: 0.9rem;
  color: $color-text;
  font-family: Arvo;
  font-weight: 700;
  margin-top: 1em;
  text-transform: uppercase;

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    font-size: 1.1rem;
  }
}

h5 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: Lato;
  font-weight: 400;
  padding: 1em 0;
  letter-spacing: 2px;

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    font-size: 1.1em;
  }
}


