.footer {
  position: relative;
  height: 18em;
  background-color: $color-dark-grey;

  &__evolve--logo {
    position: absolute;
    width: 15%;
    bottom: 49%;
    left: 50%;
    transform: translate(-50%);
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    height: 60px;
    padding-top: 20px;
    color: $color-white;
    font-size: 0.6rem;
    width: 100%;
    background-color: rgba($color-black, 0.2);
  }

  &__fusion--logo {
    width: 15%;
    position: absolute;
    right: 10%;
    bottom: 50%;
  }


  &__icons {
    position: absolute;
    left: 10%;
    bottom: 43%;
    font-family: Arvo;
    font-weight: 300;
    font-size: 0.8rem;
    letter-spacing: 2px;

    &--head {
      color: $color-white;
      display: block;
      margin-bottom: 15px;
    }
  }

  &__copyright {
    text-align: center;
  }

  &__craig {
    margin-top: 0.5em;

    a {
      color: $color-white;
    }
  }

  &__dark {
    background-image: url(images/EB_After_Dark_footer.jpg);
    background-size: cover;
    background-position: top;
  }
}

.fusion-logo {
  height: 65px;
}

.social-footer {
  text-align: left;
  height: 40px;
  border: 0;
  margin-bottom: 7%;
  margin-right: -16px;

  /* Extra Small Devices, Phones */
  @media only screen and (max-width : 480px) {
    height: 65px;
  }

  &--mail {
    padding-left: 1px;
  }

  &--github {
    margin-left: -1px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 720px) {
  .footer {
    &__fusion--logo {
      top: 24%;
      z-index: 2;
      right: 0;
      transform: translate(-50%);
    }

    &__evolve--logo {
      width: 30%;
      bottom: 48%;
    }

    &__icons {
      bottom: 41%;
      font-family: Arvo;
      font-weight: 300;
      font-size: 6pt;
      letter-spacing: 2px;
    }
  }

  .social-footer {
    margin-bottom: 4%;
    margin-right: -24px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .footer {
    height: 66em;

    &__fusion--logo {
      width: 30%;
      top: 22%;
      left: 50%;
      // transform: translate(-50%);
    }

    &__evolve--logo {
      position: absolute;
      width: 80%;
      bottom: 81%;
      left: 50%;
    }

    &__icons {
      left: 25%;
      bottom: 6%;
      font-size: 10pt;
      position: absolute;
    }
  }

  .evolve-logo {
    height: 90px;
  }

  .fusion-logo {
    height: 80px;
  }
}
