/* Do not edit abstracts/_sprite directly as it is generated automatically by Gulp.
Instead edit gulp.js */


.icon {
  background-image: url('/dist/images/sprites/sprite-1d3dc530.svg');
}

.icon--FusionDB-Logo {
  width: 135.1px;
  height: 82.7px;
  background-position: 99.16337438996048% 23.768895483631642%;
}


.icon--FusionDB-Logo-white {
  width: 135.1px;
  height: 82.7px;
  background-position: 99.16337438996048% 31.438375220254105%;
}


.icon--Twitter-icon {
  width: 74.9px;
  height: 60.9px;
  background-position: 94.7450225741988% 66.33033360603582%;
}


.icon--ev-burger-icon {
  width: 87.5px;
  height: 62.3px;
  background-position: 95.63690698543144% 38.38172385546555%;
}


.icon--ev-email-icon {
  width: 86.8px;
  height: 81.9px;
  background-position: 95.586917562724% 44.85219164118247%;
}


.icon--ev-github-icon {
  width: 86.8px;
  height: 81.9px;
  background-position: 95.586917562724% 60.03150773792976%;
}


.icon--ev-logo {
  width: 1280.1px;
  height: 580.5px;
  background-position: 0 100%;
}


.icon--ev-logo-white {
  width: 1280.1px;
  height: 580.5px;
  background-position: 0 0;
}


.icon--ev-twitter-icon {
  width: 86.8px;
  height: 81.9px;
  background-position: 95.586917562724% 52.441849689556115%;
}


.icon--github-icon {
  width: 32.6px;
  height: 31.8px;
  background-position: 98.14841395148557% 37.345023025150546%;
}


.icon--shape-icon {
  width: 145px;
  height: 256.3px;
  background-position: 99.92974238875877% 0;
}
