ul {
  list-style-type: none;
  padding: 0 px;
  display: flex;
  justify-content: center;
}

li a {
  color: $color-text;
  display: block;
  padding: 40px 13px;
  font-family: Arvo;
  font-weight: 400;
  font-size: 12pt;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: $color-blue;
    font-weight: 700;
  }
}


/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  li a {
    padding: 25px 15px;
    letter-spacing: 1px;
  }
}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
  li a {
    padding: 25px 10px;
    letter-spacing: 1px;
  }
}

// /* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  li a {
    padding: 25px 8px;
    letter-spacing: 1px;
  }
}


.menu {
  display: block;
  padding-bottom: 2em;

  @media only screen and (max-width : 480px) {
    display: none;
  }

  &__dark {
    li a {
      color: $color-white;
      text-decoration: none;
    }
  }
}

.footer-menu {
  display: none;

  @media only screen and (max-width : 480px) {
    position: absolute;
    display: block;
    padding-top: 20em;
    text-align: center;
    z-index: 10;
    transform: translate(-50%);
    margin: 0 50%;
  }

  ul {
    list-style-type: none;
    padding: 0 px;
    display: inline;
    justify-content: center;
  }

  li a {
    color: $color-white;
    display: block;
    padding: 14px 26px;
    font-family: Arvo;
    font-weight: 400;
    font-size: 19pt;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:hover {
      color: $color-light-blue;
      font-weight: 700;
      text-decoration: none;
    }
  }
}
