.main-area {
  margin-bottom: 8em;
  margin-top: 2em;

  &__form {
    background-color: $color-white;
    padding: 2em 4em;
    display: block;
    box-shadow: 20px 20px 50px rgba($color-black, 0.3);

    &--heading {
      font-size: 18px;
      padding: 0;
    }

    &--button {
      text-align: center;
      padding: 0;
    }
  }

  .top-form,
  .middle-form,
  .bottom-form {
    width: 100%;
    min-height: 65px;
    margin: 10px 0;

    input[type="text"] {
      width: 100%;
      padding: 10px;
      border: solid 1px rgba($color-black, 0.3);
      letter-spacing: 0.5px;
      font-family: Lato;
      font-weight: 300;
      font-size: 0.8rem;
    }
  }

  .inner-form {
    width: 49%;
    float: left;
    margin-right: 2%;
  }

  .inner-form:last-child {
    margin-right: 0;
  }

  .middle-form {
    clear: both;
  }

  .bottom-form {
    &__inner {
      width: 100%;
    }
  }

  textarea {
    height: 120px;
    width: 100%;
    padding: 5px;
    border: 1px solid rgba($color-black, 0.2);
  }
}

.label {
  color: rgba($color-primary, 0.3);
  font-family: Lato;
  font-weight: 400;
  padding: 0 0 5px 0;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .main-area {
    &__form {
      margin: 0 0;
      padding: 1% 4%;

      &--heading {
        font-size: 17px;
      }

      &--button {
        padding: 40px 0;
      }
    }

    .top-form,
    .middle-form,
    .bottom-form {
      width: 100%;
      min-height: 65px;
      margin: 10px 0;
    }

    .inner-form {
      width: 100%;
      margin-bottom: 5%;
      display: block;
      margin-right: 2%;
    }

    .inner-form:last-child {
      margin-right: 0;
    }

    textarea {
      height: 120px;
      width: 100%;
    }
  }

  .label {
    color: rgba(41, 48, 70, 0.7);
    font-family: Lato;
    font-weight: 300;
    padding: 0 0 5px 0;
    letter-spacing: 1px;
    font-size: 1.3em;
  }
}
